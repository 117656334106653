import React, { useState } from "react";
import {
  TextField,
  Button,
  Card,
  CardMedia,
  Container,
  Grid,
} from "@material-ui/core/";
import useStyles from "./styleMain";
import CryptoJS from "crypto-js";
import Swal from 'sweetalert2';
import imageUrl from './img/favicon.png'
import imageUrl1 from './img/irecharge.png'

function Main() {
  const classes = useStyles();
  const [email, setEmail] = useState("");
  const [transactionId, setTransactionId] = useState("");
  const [meterNo, setMeterNo] = useState("");
  const [isLoadin, setIsLoadin] = useState(false);
 
  const handleEmailChange = (e) => {
    let value = e.target.value;
    setEmail(value);
  };
  const handleTransIdChange = (e) => {
    let value = e.target.value;
    setTransactionId(value);
  };
  const handleMeterNoChange = (e) => {
    let value = e.target.value;
    setMeterNo(value);
  };
  async function handleSubmit(e) {
    e.preventDefault();
    setIsLoadin(true)

    try {
  
        const key = CryptoJS.HmacSHA512(meterNo, "123NDSx").toString();
        const data = {
            passcode: key,
            action: 'resend_receipt',
            receiver: meterNo,
            transaction_id: transactionId,
            email: email        
      }

      const response = await fetch('https://irecharge.com.ng/api/misc/resend_receipt.php', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data)
      });
      const responseData = await response.json();

    //   setTimeout(function(){ 
    //       if(!responseData){
    //         Swal.fire({
    //             icon: 'error',
    //             title: 'Lost internet Connectivity',
    //             text: 'Please check your internet connection and try again'
    //           })
    //         setIsLoadin(false)
    //       } }, 5000);

      console.log(responseData);
    if (responseData.status === "0"){
        Swal.fire({
            icon: 'success',
            text: responseData.message,
            position: 'top-end',
          });

        setIsLoadin(false) 
    }else{
        Swal.fire({
            icon: 'error',
            text: responseData.message,
            position: 'top-end'
          })
        setIsLoadin(false) 
    }
    } catch (error) {
        Swal.fire({
            icon: 'error',
            title: 'Unable to connect',
            text: 'Please check your internet connection and try again'
          })
        setIsLoadin(false)
    }

  }
  return (
    <>
      <Container className={classes.container}>
        <Grid
          container
          direction="row"
          alignItems="stretch"
          spacing={3}
          className={classes.grid}
        >
        <Grid item xs={12} sm={4} md={3} lg={3} xl={2}>
            <CardMedia
              component="img"
              alt="iRecharge - Receipts"
              image={imageUrl}
              title="iRecharge - Receipts"
              className={classes.media}
            />
          </Grid>
        </Grid>
        <form
          className={classes.form}
          onSubmit={handleSubmit}
          noValidate
          autoComplete="off"
        >
          <Card className={classes.card}>
            <CardMedia
              component="img"
              alt="irecharge_logo"
              image={imageUrl1}
              title="irecharge_logo"
            />
            
            <TextField
              id="transactionId"
              label="Transaction ID"
              type="text"
              variant="outlined"
              value={transactionId}
              onChange={handleTransIdChange}
              className={classes.textfield}
            />
            <TextField
              id="meterNo"
              label="Meter Number"
              type="text"
              variant="outlined"
              value={meterNo}
              onChange={handleMeterNoChange}
              className={classes.textfield}
            />
            <TextField
              id="email"
              label="Email"
              type="email"
              variant="outlined"
              value={email}
              onChange={handleEmailChange}
              className={classes.textfield}
            />
            <Button
              variant="contained"
              type="submit"
              color="primary"
              className={classes.btn}
              disabled={isLoadin}
            >
              Submit
            </Button>
          </Card>
        </form>
      </Container>
    </>
  );
}
export default Main;

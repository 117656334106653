import logo from './logo.svg';
import './App.css';
import Main from './Main'
import { ThemeProvider, createMuiTheme, CssBaseline } from "@material-ui/core";

const theme = createMuiTheme({
  palette: {
    secondary: {
      main: "#ffffff"
    },
    primary: {
      main: "#f25139"
    }
  }
});

function App() {
  return (
    <div className="App">
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Main/>
      </ThemeProvider>
    </div>
  );
}

export default App;
